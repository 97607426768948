.page {
    max-width: $max-width;
    margin-bottom: auto;

    article {
        padding: 24px 12px;
        border-top: 1px solid $gray-border;

        &:last-child {
        border-bottom: 1px solid $gray-border;
        }
    }

    aside {
        @include flex(column, flex-start, flex-start, visible);
        padding: 16px;
        border: 1px solid $blue-border;
        border-radius: 3px;
        background: $blue-background;
        color: $primary-text;
        white-space: break-spaces;

        svg {
            color: $blue-background;
            margin-right: 4px;
        }
    }
}

.activities-page {
    [aria-label="pagination navigation"] {
        margin-top: 20px;
    }
}

.error-page {
    @include flex();
    margin: auto;
}