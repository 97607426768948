header {
    z-index: 2;
}

.branding {
    @include flex(column, center, center, hidden);
    height: 300px;

    &__background {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -8%);
        width: 100%;
        height: auto;
        min-width: 1500px;
        min-height: 411px;
        z-index: -1;
    }

    &__logo {
        width: 105px;
        height: 105px;
        border-radius: 50%;
        margin: 42px auto 16px;
    }

    &__text-primary {
        color: $primary-text;
    }

    &__text-secondary {
        color: $primary-main;
    }
}

.nav-container {
    height: 40px;
    background: $primary-main;
}

.nav {
    @include flex();
    flex-wrap: wrap;
    max-width: $max-width;
    background: $primary-main;

    a {
        display: inline-flex;
        align-items: center;
        padding: 0 16px;
        line-height: 40px;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        color: $primary-text;
        text-decoration: none;
        transition: .15s ease-in-out;

        &:hover {
            background: $primary-dark;
        }

        &:first-child {
            border-left: 1px solid rgba(0, 0, 0, 0.15);
        }

        svg {
            font-size: 14px;
            margin-left: 4px;
        }
    }

    .active {
        background: $primary-dark;
    }

    .language-switch-container {
        margin-left: auto;

        .language-switch {
            cursor: pointer;
        }
    }
}

footer {
    width: 100%;
    border-top: 2px solid $primary-main;
    background: $gray-background;
    padding: 32px;
    margin-top: 64px;
}

.footer-content {
    @include flex(row, flex-start, space-between);
    max-width: $max-width;
}

.versions-container {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    opacity: .5;

    span {
        font-size: inherit;
    }
}

.welcome {
    max-width: 90vw;
    position: absolute;
    top: 0;
    left: 50vw;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 0 0 3px 3px;
    box-shadow: #000 0 0 3px;
    color: $primary-text;
    background: $primary-main;
    z-index: 2;

    span {
        max-width: 100%;
        position: relative;
        padding: 4px;

        &:hover b {
            transform: translateX(-50%) scale(1);
        }

        b {
            padding: 8px 16px;
            position: absolute;
            top: calc(100% + 20px);
            left: 50%;
            transform: translateX(-50%) scale(0);
            white-space: nowrap;
            background: $primary-main;

            &:before {
                content: '';
                position: absolute;
                top: -5px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: 10px;
                height: 10px;
                background: $primary-main;
            }
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: -20px;
                width: 100%;
                height: 20px;
            }
        }
    }

    button {
        display: inline-flex;
        box-sizing: content-box;
        margin-left: 8px;
        padding: 4px;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 3px;
        color: inherit;
        background: $primary-main;
        transition: .15s ease-in-out;
        cursor: pointer;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);

        &:hover {
            background: $primary-dark;
        }
    }

    a {
        color: $primary-text;
        text-decoration: none;
        padding: 4px;

        &:hover {
            background: $primary-dark;
        }
    }

    .delimiter {
        border-left: 1px solid $primary-dark;
        height: 20px;
        margin: 0 16px;
    }
}

.button-tooltip-anchor {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

*:has(.tooltip-hover-only:hover) > .tooltip:not(:hover) {
    z-index: 2;

    &:before, &:after {
        opacity: 0;
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.7;
}

.logout-container {
    @include flex(column);
}

button.secondary-button {
    background: $secondary-main;
    color: $secondary-text;

    &:hover {
        background: $secondary-dark;
    }
}

.documents-page {
    .MuiCircularProgress-root, &__empty {
        display: block;
        margin: 0 auto;
        width: fit-content;
    }
}

.table {
    table-layout: fixed;

    th, td {
        font-size: 16px;
        overflow-wrap: break-word;

        button {
            min-width: 0;
        }
    }

    th {
        font-weight: bold;
        vertical-align: top;
    }

    a {
        color: $primary-main;
    }
}

.outbound-area-container {
    width: 95vw;
    left: 50%;
    transform: translate(-50%);
}

[aria-label="pagination navigation"] {
    margin-top: 15px;
}

.user-field {
    @include flex(row, center, flex-start);
    gap: 16px;
    margin-bottom: 8px;

    p {
      min-width: 120px;
    }

    input {
        padding: 4px 8px;
    }
}

div.timer {
    font-size: 20px;
    font-weight: bold;
    margin-top:-15px;
    margin-bottom: 5px;
}

.broadcast {
    &-nav {
        @include flex(row, start, start);
        margin-top: 16px;

        a {
            padding: 0 16px;
            line-height: 40px;
            text-decoration: none;

            background: $primary-main;
            color: $primary-text;
            border: 1px solid $primary-main;
        }
        .active {
            background: $primary-dark;
            color: $primary-text;
            border-color: $primary-dark;
        }
    }
}