@import "../../../node_modules/@fontsource/source-sans-pro";
@import "../../../node_modules/@fontsource/source-sans-pro/700.css";
@import 'theme';
// First mock's theme, then common styles
@import 'common-frontend/assets/scss';
@import 'pages';
@import 'components';

* {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

#root {
  @include flex(column, center, space-between);
  min-height: 100vh;

  & > * {
    width: 100%;
  }
}

html {
  font-family: 'Source Sans Pro', sans-serif;
}
input[type="file"] {
  display: none;
}

.mt-8 {
  margin-top: 8px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-48 {
  margin-top: 48px !important;
}
.mt-auto {
  margin-top: auto !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-48 {
  margin-bottom: 48px !important;
}
.mb-auto {
  margin-bottom: auto !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.h-100 {
  height: 100%;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.notistack-MuiContent-info {
  background: #3e7cb1 !important;
}

.MuiButtonBase-root:disabled {
  background: rgba(255, 255, 255, 0.3) !important;
  cursor: not-allowed;
  pointer-events: auto;
}
