// palette
$primary-main: #004b76;
$primary-dark: #739CB4;
$primary-text: #fff;

$secondary-light: #fff;
$secondary-main: #f8f8f8;
$secondary-dark: #dfdfdf;
$secondary-text: #222;

$blue-background: #3e7cb1;
$blue-border: #007194;

$gray-background: #f8f8f8;
$gray-border: #dfdfdf;

// variables
$max-width: 1100px;